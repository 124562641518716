export default [
    {
        title: 'Contact me',
        content: `
        The fastest way to contact me is to mail me at
        <a href="mailto:serhiicho@protonmail.com" class="text-main">serhiicho@protonmail.com</a>.
        `,
        links: [
            {
                img: '/storage/icons/x.webp',
                href: 'https://x.com/SerhiiCho',
                title: 'X (Twitter)',
            },
        ],
    },
    {
        title: 'Follow me',
        content:
            "There are some places where I'm usually hanging out. I spend a lot of time on websites that are linked below.",
        links: [
            {
                img: '/storage/icons/youtube.webp',
                href: 'https://www.youtube.com/@serhiicho',
                title: 'YouTube',
            },
            {
                img: '/storage/icons/laracasts.webp',
                href: 'https://laracasts.com/@SerhiiCho',
                title: 'Laracasts',
            },
            {
                img: '/storage/icons/codewars.webp',
                href: 'https://www.codewars.com/users/serhii_cho',
                title: 'Codewars',
            },
            {
                img: '/storage/icons/codepen.webp',
                href: 'https://codepen.io/serhii_cho',
                title: 'Codepen',
            },
            {
                img: '/storage/icons/bymeacoffee.webp',
                href: 'https://www.buymeacoffee.com/serhiicho',
                title: 'Buy me a coffee',
            },
        ],
    },
    {
        title: 'Open source',
        content:
            "There are also libraries and packages that I've contributed to the open-source community.",
        links: [
            {
                img: '/storage/icons/github.webp',
                href: 'https://github.com/SerhiiCho',
                title: 'GitHub',
            },
            {
                img: '/storage/icons/packagist.webp',
                href: 'https://packagist.org/packages/serhii',
                title: 'Packagist',
            },
            {
                img: '/storage/icons/npm.webp',
                href: 'https://www.npmjs.com/~serhiicho',
                title: 'NPM',
            },
        ],
    },
]

import type { Tag } from '@shared/types/models'

const tag: Tag = {
    id: 0,
    title: 'Show everything',
    description: 'Show every post in the blog',
    name: 'everything',
    color: '#826388',
}

export default tag

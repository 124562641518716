<template>
    <transition-group
        enter-active-class="duration-300 ease-out"
        enter-from-class="transform opacity-0 -translate-y-5"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="duration-300 ease-in"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="transform -translate-y-5"
        appear
    >
        <slot />
    </transition-group>
</template>
